<template>
  <div @click="gohome">
      <img src="../assets/img/404.jpg" alt="" >
  </div>
</template>

<script>
export default {
  methods:{
    gohome(){
      this.$router.push('/stat')
    }
  }
}
</script>

<style lang="scss" scoped>
div{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    overflow: hidden;
    // padding-top: 200px;
    padding: 0 600px;
    background: #F9FBFA;
}

</style>